import { Affix, AppShell, Center, Group, Header, Stack } from "@mantine/core";
import { useRouter } from "next/router";
import { memo, useEffect, useState } from "react";
import { Container } from "tabler-icons-react";
import { BottomMenuLayout } from "./BottomMenu.layout";
import { FooterLayout } from "./Footer.layout";
import { HeaderLayout } from "./Header.layout";
import AsideLayout from "./Aside.layout";
import { ROUTES } from "../../utils/routePaths";

// const MemoizedBottomMenu = memo(BottomMenuLayout);
// const MemoizedHeader = memo(HeaderLayout);

const Layout = ({
	children,
	title,
	subTitle,
	momentId,
}: {
	children: any;
	title?: string;
	subTitle?: string;
	momentId?: string;
}) => {
	const router = useRouter();
	const currentRoute = router.pathname;

	const routesWithNoBottomMenu = [
		ROUTES.account.base,
		ROUTES.home,
		ROUTES.moment.base,
		ROUTES.vendor.base,
		ROUTES.inspiration.base,
	];
	const isBottomShouldOn = routesWithNoBottomMenu.includes(currentRoute);

	const [width, setWidth] = useState(0);

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}

		// Check if window is available (browser environment) before adding event listener
		if (typeof window !== "undefined") {
			setWidth(window.innerWidth);
			window.addEventListener("resize", handleResize);
		}

		return () => {
			// Check if window is available (browser environment) before removing event listener
			if (typeof window !== "undefined") {
				window.removeEventListener("resize", handleResize);
			}
		};
	}, []);

	return (
		<>
			<Center>
				<HeaderLayout momentId={momentId} title={title && title} subTitle={subTitle && subTitle} />
				<Stack
					sx={{
						backgroundColor: "#ffffff",
						width: "100%",
						maxWidth: "520px",
						minHeight: "100svh",
					}}
					py={76}
					px={16}
				>
					{children}
				</Stack>
				{/* {!isBottomShouldOff && <BottomMenuLayout />} */}
				{isBottomShouldOn && <BottomMenuLayout />}
			</Center>
		</>
	);
};

export default Layout;
