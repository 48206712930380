import { ActionIcon, Affix, Center, Footer, Group } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { useRef } from "react";
import { ROUTES } from "../../utils/routePaths";

const MenuIcon = ({ href, iconFill, iconLine, isActive }: any) => (
	<Link style={{ display: "flex", justifyContent: "center" }} href={href}>
		<ActionIcon
			component="span"
			radius="md"
			size="lg"
			variant={isActive ? "transparent" : "transparent"}
			color={isActive ? "pink" : "gray"}
		>
			<i className={isActive ? iconFill : iconLine}></i>
		</ActionIcon>
	</Link>
);

export const BottomMenuLayout = () => {
	const ref = useRef();
	const router = useRouter();
	const currentRoute = router.pathname;

	const routesConfig = [
		{ href: ROUTES.home, iconFill: "ri-home-smile-2-line", iconLine: "ri-home-smile-2-line" },
		{ href: ROUTES.inspiration.base, iconFill: "ri-bard-line", iconLine: "ri-bard-line" },
		// { href: ROUTES.moment.base, iconFill: "ri-heart-2-line", iconLine: "ri-heart-2-line" },
		{ href: ROUTES.vendor.base, iconFill: "ri-settings-line", iconLine: "ri-settings-line" },
		// { href: ROUTES.market.base, iconFill: "ri-shopping-bag-3-line", iconLine: "ri-shopping-bag-3-line" },
		{ href: ROUTES.account.base, iconFill: "ri-user-6-line", iconLine: "ri-user-6-line" },
	];

	return (
		<Affix zIndex={10} sx={{ width: "100%" }} position={{ bottom: 0 }}>
			<Center>
				<Footer
					style={{
						width: "100%",
						maxWidth: "520px",
					}}
					height={60}
					py="sm"
				>
					<Group spacing={0} position="apart" grow>
						{routesConfig.map(({ href, iconFill, iconLine }) => (
							<MenuIcon
								key={href}
								href={href}
								iconFill={iconFill}
								iconLine={iconLine}
								isActive={currentRoute === href}
							/>
						))}
					</Group>
				</Footer>
			</Center>
		</Affix>
	);
};
