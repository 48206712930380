export const ROUTES = {
	invitation: {
		base: "/undangan",
		detail: "/undangan/[id]",
		create: "/undangan/[id]/buat",
		edit: {
			event: "/undangan/[id]/edit/event",
			feature: "/undangan/[id]/edit/feature",
			theme: "/undangan/[id]/edit/theme",
			url: "/undangan/[id]/edit/url",
		},
	},

	moment: {
		base: "/momen",
		// detail: "/momen/[id]",
		detail: "/[id]",
		create: "/buat-baru",
		checklist: {
			detail: "/[id]/checklist",
		},
		invitation: {
			detail: "/[id]/invitation",
			theme: {
				base: "/[id]/invitation/tema",
			},
			create: "/undangan/[id]/buat",
			edit: {
				event: "/undangan/[id]/edit/event",
				feature: "/undangan/[id]/edit/feature",
				theme: "/undangan/[id]/edit/theme",
				url: "/undangan/[id]/edit/url",
			},
		},
	},

	theme: {
		base: "/tema",
		detail: "/tema/[id]",
		clone: "/tema/[id]/salin",
		create: "/tema/buat",
	},

	billing: {
		base: "/billing",
	},

	promo: {
		base: "/promo",
	},

	vendor: {
		base: "/vendor",
	},

	notification: {
		base: "/notifications",
	},

	market: {
		base: "/market",
	},

	inspiration: {
		base: "/inspiration",
	},

	account: {
		base: "/account",
		referral: {
			base: "/journey",
		},
	},
	home: "/",
};
