import {
	ActionIcon,
	Affix,
	Box,
	Button,
	Center,
	Container,
	Flex,
	Group,
	Header,
	Image,
	Stack,
	Text,
	createStyles,
	rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ROUTES } from "../../utils/routePaths";

export const HeaderLayout = ({
	title,
	subTitle,
	momentId,
}: {
	title?: string;
	subTitle?: string;
	momentId?: string;
}) => {
	const { data: session } = useSession();
	const [deferredPrompt, setDeferredPrompt] = useState(null);
	const [installable, setInstallable] = useState(false); // Track installable state
	const [bannerVisible, setBannerVisible] = useState(true);

	// Check if the banner was closed within the last 24 hours
	useEffect(() => {
		const closedAt = localStorage.getItem("installBannerClosedAt");
		if (closedAt) {
			const now = new Date().getTime();
			const timeDiff = now - parseInt(closedAt, 10);

			// 24 hours = 86400000 milliseconds
			if (timeDiff < 86400000) {
				setBannerVisible(false); // Don't show the banner if it's closed within the last 24 hours
			}
		}
	}, []);

	useEffect(() => {
		const handleBeforeInstallPrompt = (e) => {
			e.preventDefault();
			setDeferredPrompt(e);
			setInstallable(true);
		};

		window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

		return () => window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
	}, []);

	const handleInstallClick = () => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			deferredPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === "accepted") {
					console.log("User accepted the install prompt");
				} else {
					console.log("User dismissed the install prompt");
				}
				setDeferredPrompt(null);
				setInstallable(false);
			});
		}
	};

	const handleCloseBanner = () => {
		setBannerVisible(false);
		localStorage.setItem("installBannerClosedAt", new Date().getTime().toString());
	};

	const router = useRouter();
	const currentRoute = router.pathname;

	const getTitle = () => {
		// if (currentRoute === ROUTES.home) return "Bahagia.day";
		if (currentRoute === ROUTES.promo.base) return "Kumpulan Promo";
		if (currentRoute === ROUTES.inspiration.base) return "Inspiration Feed";
		if (currentRoute === ROUTES.vendor.base) return "Vendor Suggestion";
		if (currentRoute === ROUTES.notification.base) return "Notifikasi";
		if (currentRoute === ROUTES.billing.base) return "Riwayat Pesanan";
		if (currentRoute === ROUTES.vendor.base) return "Semua Vendor";
		if (currentRoute === ROUTES.account.base) return "Akun";
		if (currentRoute === ROUTES.account.referral.base) return "Referral Tim Bahagia";
		if (currentRoute === ROUTES.moment.invitation.create) return "Buat Undangan Baru";
		if (currentRoute === ROUTES.moment.invitation.detail) return "Undangan Digital";
		if (currentRoute === ROUTES.moment.checklist.detail) return "Checklist / To-Do List";
		if (currentRoute === ROUTES.moment.invitation.theme.base) return "Pilih Tema";
		if (currentRoute === ROUTES.moment.detail) return "Detail Momen";
		return title; // Fallback to the `title` prop if no match
	};

	const mobileScreen = useMediaQuery("(max-width: 540px)");
	const { classes, cx } = useStyles();

	return (
		<Affix zIndex={10} sx={{ width: "100%" }} position={{ top: 0 }}>
			<Center>
				{installable && bannerVisible && (
					<Group
						position="apart"
						sx={{
							backgroundColor: "#FEF1F6",
							color: "#fff",
							padding: "8px 16px",
							textAlign: "center",
							width: "100%",
							maxWidth: "520px",
							marginLeft: "auto",
							marginRight: "auto",
							// position: "fixed",
							top: 0,
							left: 0,
							zIndex: 100,
							alignItems: "center",
							display: "flex",
						}}
					>
						<Text component="p" m={0} color="dark" weight={500} sx={{ fontSize: "14px" }}>
							Install aplikasi Bahagia.day
						</Text>
						<Flex align={"center"} justify={"center"} gap={12}>
							<Button
								size="sm"
								h={"28px"}
								radius={"md"}
								px={8}
								py={0}
								m={0}
								color="pink"
								variant="filled"
								// sx={{ backgroundColor: "pink" }}
								onClick={handleInstallClick}
							>
								<Text component="span" p={0} m={0} size={"xs"} color="white">
									Install
								</Text>
							</Button>
							<Text
								component="i"
								color="dark"
								size={20}
								sx={{ cursor: "pointer" }}
								className="ri-close-line"
								onClick={handleCloseBanner}
							></Text>
						</Flex>
					</Group>
				)}
				<Affix zIndex={9} sx={{ width: "100%" }} position={{ top: installable && bannerVisible ? 44 : 0 }}>
					<Center>
						<Header
							style={{
								width: "100%",
								marginLeft: "auto",
								marginRight: "auto",
								maxWidth: "520px",
								display: "flex",
								alignItems: "center",
							}}
							height={60}
						>
							<Flex w={"100%"} justify={"center"}>
								<Group
									position="apart"
									style={{
										display: "flex",
										flex: 1,
										paddingRight: "16px",
										paddingLeft: "16px",
										alignItems: "center",
										height: "100%",
										// maxWidth: !mobileScreen && "800px",
									}}
								>
									{currentRoute === ROUTES.moment.invitation.theme.base ||
									currentRoute === ROUTES.moment.invitation.detail ||
									currentRoute === ROUTES.moment.checklist.detail ||
									currentRoute === ROUTES.moment.invitation.create ||
									currentRoute === ROUTES.billing.base ||
									currentRoute === ROUTES.moment.detail ? (
										<ActionIcon
											component="a"
											onClick={() => {
												switch (router.pathname) {
													case "/undangan/[id]":
														router.push("/undangan");
														break;
													case "/tema":
														router.push("/undangan");
														break;
													case "/tema/[id]":
														router.push("/tema");
														break;
													case "/momen/[id]":
														router.push("/momen");
														break;
													case "/[id]":
														router.push("/");
														break;
													case "/[id]/invitation":
														router.push({
															pathname: "/[id]",
															query: { id: momentId }, // Replace `yourIdValue` with the actual ID
														});
														break;
													default:
														router.back();
														break;
												}
											}}
											size="lg"
											variant="transparent"
											color="gray"
										>
											<i className="ri-arrow-left-line"></i>
										</ActionIcon>
									) : (
										<Link href="/">
											<Image
												radius="md"
												src="/image/bahagia-day-logo.svg"
												alt="empty wedding"
												style={{ maxWidth: "26px" }}
											/>
										</Link>
									)}

									{getTitle() ? (
										<Link href="#">
											<Stack spacing={0}>
												<Text weight={500} component="p" align="center" m={0} size={"sm"}>
													{getTitle()}
												</Text>
												{subTitle && (
													<Text align="center" color="gray.7" component="p" m={0} size={10}>
														{subTitle}
													</Text>
												)}
											</Stack>
										</Link>
									) : (
										<Link href="#">
											<Stack spacing={0}>
												<Flex justify={"center"} align={"center"} gap={8}>
													<Box w={"100%"} maw={100}>
														<Image src="/image/bahagia-logo-type.svg" alt="empty wedding" />
													</Box>
												</Flex>
											</Stack>
										</Link>
									)}

									<Link href="https://tutorial.bahagia.day" target="_blank">
										<ActionIcon
											radius="md"
											size="lg"
											variant={"transparent"}
											// variant={!ROUTES.notification ? "transparent" : "light"}
											// color={!ROUTES.notification ? "gray" : "pink"}
										>
											<ActionIcon radius="md" size="lg">
												<Text component="i" size={20} color="gray" className="ri-question-line"></Text>
											</ActionIcon>
										</ActionIcon>
									</Link>
								</Group>
							</Flex>
						</Header>
					</Center>
				</Affix>
			</Center>
		</Affix>
	);
};

const useStyles = createStyles((theme) => ({
	title: {
		boxSizing: "border-box",
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		marginBottom: theme.spacing.xl,
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		padding: theme.spacing.md,
		paddingTop: rem(18),
		height: rem(60),
		borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]}`,
	},

	logo: {
		boxSizing: "border-box",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		height: rem(60),
		paddingTop: theme.spacing.md,
		borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]}`,
		marginBottom: theme.spacing.xl,
	},
}));
